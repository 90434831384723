import { MenuEntry } from '@pancakeswap-libs/uikit'

export const links = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
    /*
  {
    label: 'Launchpads',
    icon: 'RocketIcon',
    items: [
      {
        label: 'Create Launch',
        href: 'https://github.com/goosedefi/',
      },
      {
        label: 'Create Token',
        href: 'https://github.com/goosedefi/',
      },
      {
        label: 'Launchpad List',
        href: 'https://goosedefi.gitbook.io/goose-finance/',
      },
    ],
  },
  {
    label: 'Stake',
    icon: 'StakeIcon',
    href: '#',
  },
  {
    label: 'Seed Sale',
    icon: 'DealIcon',
    href: '/ifos',
  },
  {
    label : 'Poap NFT',
    icon : 'NftIcon',
    href : '/poap'
  }, */
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: 'https://t.me/aerospacefinance',
  },
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/aerospacedefi',
  },
  {
    label: 'Docs',
    icon: 'DocsIcon',
    href: 'https://docs.aerospace.finance',
  },
]

export const socials = [
  {
    label: "Telegram",
    icon: "TelegramIcon",
    items: [
      {
        label: "Global",
        href: "https://t.me/cosmosiumfinance",
      },
      // {
      //   label: "Bahasa Indonesia",
      //   href: "https://t.me/PancakeSwapIndonesia",
      // },
      {
        label: "Indian",
        href: "https://t.me/cosmosiumindia",
      },
      // {
      //   label: "Tiếng Việt",
      //   href: "https://t.me/PancakeSwapVN",
      // },
      {
        label: "Korean",
        href: "https://t.me/cosmosiumkorea",
      },
      // {
      //   label: "Português",
      //   href: "https://t.me/PancakeSwapPortuguese",
      // },
    ],
  },
  {
    label: "Twitter",
    icon: "TwitterIcon",
    href: "https://twitter.com/cosmosium",
  },
  {
    label: "Reddit",
    icon: "RedditIcon",
    href: "https://www.reddit.com/r/Cosmosium/",
  },
];